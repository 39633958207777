import api from "@/api"
import { PmsCommentReplay } from "@/api/modules/comment"
import Card from "@/components/Card"
import Pagination from "@/components/Pagination"
import Search from "@/components/Search"
import { PaginationRow, SearchListRow } from "@/models/type"
import { parseTime } from "@/utils"
import { ElMessageBox, ElNotification } from "element-plus"
import { Vue } from "vue-class-component"
import './index.scss'

export default class Comment extends Vue {

  total = 0
  type = ''

  searchList: SearchListRow[] = [
    { label: '商品货号', type: 'input', placeholder: '请输入商品货号', submitKey: 'productSn' },
    { label: '时间', type: 'time',  submitKey: ['startTime', 'endTime'] }
  ]

  searchObj: any = {}
  tableData: any[] = []

  url = ''
  srcList: string[] = []

  commentDom(): JSX.Element {
    return <>
      {this.tableData.map((item, index) => <div
        class="mall_comment-list"
      >
        <div class="mall_comment-list-info">
          <img src={item.icon} alt="" />
          <span>{item.username}</span>
        </div>
        <div class="mall_comment-list-right">
          <div class="mall_comment-list-header">
            <div class="mall_comment-list-header-content">
              <p>{item.content}</p>
              <div class="mall_comment-list-time">商品货号：{item.product_sn}</div>
              <div class="mall_comment-list-time">{parseTime(new Date(item.createTime).getTime())}</div>

            </div>
            <div class="mall_comment-list-header-set">
              {
                !item.replyBool && !item.replayContent
                  && <span class="btn-primary" onClick={() => this.reply(index)}>回复</span>
              }
              <span class="btn-danger" onClick={() => this.replyDel(item.id)}>删除</span>
            </div>
          </div>
          <div class="mall_comment-list-img">
            {item.pics?.split(',')?.map(i => <img src={i} alt="" onClick={() => this.showImg(item.pics, i)} />)}
          </div>
          {
            item.replayContent && !item.replyBool
              && <div class="mall_comment-list-reply">
                <div class="reply-title">道义空间回复：</div>
                <div class="reply-content">{ item.replayContent }</div>
                <div class="reply-submit">
                  <span class="btn-primary" onClick={() => this.replyEdit(item, index)}>编辑</span>
                  <span class="btn-danger" onClick={() => this.deleteReplayComment(item.id)}>删除</span>
                </div>
              </div>
          }
          {item.replyBool &&  <div class="mall_comment-list-areply">
            <div class="mall_comment-list-areply-left">
              <span>道义空间回复：</span>
              <span>最多150字</span>
            </div>
            <el-input type="textarea" v-model={item.replyContent} clearable rows={4} maxlength={150} show-word-limit />
            <div class="mall_comment-list-areply-right">
              <i class="el-icon-close" onClick={() => this.closeReply(index)}></i>
              <span class="btn-primary" onClick={() => this.submit(item)}>发布</span>
            </div>
          </div>}
        </div>
      </div>)}
    </>
  }

  showImg(srcList: string, url: string): void {
    this.url = url
    this.srcList = srcList.split(',')
    this.imgViewerShow()
  }

  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  replyEdit(row: any, index: number): void {
    this.type = 'edit'
    this.tableData[index].replyContent = row.replayContent
    this.tableData[index].replyBool = true
  }

  deleteReplayComment(id: number): void {
    ElMessageBox.confirm('确定删除该条回复？', '删除回复', {
      type: 'warning'
    }).then(() => {
      api.deleteReplayComment(id).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '删除成功'
        })
        this.getList()
      })
    }).catch(() => false)
  }

  /**
   * @description 删除评论
   * @author Jinx
   * @date 2021-11-01 11:53:15
   * @param {number} id
   * @memberof Comment
   */
  replyDel(id: number): void {
    ElMessageBox.confirm('确定删除该条评论？', '删除评论', {
      type: 'warning'
    }).then(() => {
      api.replayCommentDelete(id).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '删除成功'
        })
        this.getList()
      })
    }).catch(() => false)
  }

  /**
   * @description 发布评论
   * @author Jinx
   * @date 2021-11-01 11:35:17
   * @param {*} row
   * @memberof Comment
   */
  submit(row: any): void {
    const obj:PmsCommentReplay = {}
    obj.commentId = row.id
    obj.id = row.id
    obj.content = row.replyContent
    obj.type = 1
    if(this.type === 'add') {
      delete obj.id
      api.replayComment(obj).then(res => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '回复成功'
        })
        this.getList()
      })
    } else {
      api.editReplayComment(obj).then(res => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '编辑回复成功'
        })
        this.getList()
      })
    }
  }

  /**
   * @description 回复
   * @author Jinx
   * @date 2021-11-01 11:30:51
   * @param {number} index
   * @memberof Comment
   */
  reply(index: number): void {
    this.type = 'add'
    this.tableData[index].replyBool = true
  }

  /**
   * @description 关闭回复面板
   * @author Jinx
   * @date 2021-11-01 11:32:38
   * @param {number} index
   * @memberof Comment
   */
  closeReply(index: number): void {
    this.tableData[index].replyBool = false
    this.tableData[index].replyContent = ''
  }
  onSearch(obj): void {
    this.searchObj = Object.assign({}, obj)
    this.getList()
  }

  getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
    api.getCommentList({pageNum, pageSize, ...this.searchObj}).then(res => {
      res.data.list.forEach(item => {
        item.replyBool = false // 回复按钮
        item.replyContent = '' // 回复内容
      })
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }

  render(): JSX.Element {
    return (
      <div class="mall_comment">
        <Card title="评论管理" class="mall_comment-card">
          <Search list={this.searchList} class="mall_comment-search" onSearch={this.onSearch} />
          <el-divider></el-divider>
          {this.commentDom()}
          <Pagination ref="pagination" total={this.total} onSizeChange={this.getList} onCurrentChange={this.getList} />
        </Card>
        {this.imgViewerDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }
}
